<script lang="ts">import { onMount, onDestroy } from 'svelte';
import Tailwindcss from "./Tailwindcss.svelte";
let time;
let interval;
onMount(() => {
    interval = setInterval(() => {
        const now = new Date();
        if (now.getMinutes() === 0) {
            time = 'It is Garlic Bread Time';
        }
        else if (now.getMinutes() === 1) {
            time = `${now.getMinutes()} minute past Garlic Bread Time`;
        }
        else {
            time = `${now.getMinutes()} minutes past Garlic Bread Time`;
        }
    }, 1000);
});
onDestroy(() => {
    clearInterval(interval);
});
</script>
<style global>:global(html), :global(body) {
  background-image: url('../bg.png');
  background-repeat: repeat;
}

:global(h1) {
  border: 5px solid #DE9C4B;
  font-family: 'Indie Flower', cursive;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}</style>
<Tailwindcss />
<div class="min-h-screen grid grid-cols-1 items-center place-content-center ">
  <h1 class="mx-auto w-4/5 lg:w-1/3 text-center bg-white p-5 rounded transition-all duration-150 text-5xl">
    {time ? time : 'Loading'}
  </h1>
</div>